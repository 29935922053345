<template>
  <div class="banner-area">
    <Swiper ref="swiper" :options="swiperOptions" class="banner-swiper">
      <SwiperSlide v-for="(item, index) in bannerList" :key="index">
        <div class="banner-slide-item" :style="{
          'background-image': `url(${item.bg})`,
        }">
          <div class="w">

            <div class="item-info">
              <h3 class="tit" v-html="item.title"></h3>
              <p class="txt" v-html="item.description"></p>
              <div class="btn-box">
                <el-button type="primary" class="btn-banner" @click="getDetails(item.link)" :clstag="clstag(item.linkClstag, '')">了解详情</el-button>
                <el-button v-if="item.video" plain class="btn-banner" :clstag="clstag(item.videoClstag, '')" @click="playVideo(item.video)">平台简介<i class="icon-play"></i></el-button>
              </div>
            </div>

          </div>
        </div>
      </SwiperSlide>
      <div class="banner-swiper-pagination-container" slot="pagination"></div>
    </Swiper>

    <!-- 资讯 -->
    <div class="info-list">
      <div class="info-list-hd">
        <h5 class="tit">平台公告</h5>
        <a href="https://joyspace.jd.com/teams/ptJ4GlBa9EQz0XL8vLiNF/mAfEojrLI8xTRl8qLrvn" target="_blank"
          class="more" :clstag="clstag('Management_1702468662190|25', '')">更多<i class=""></i></a>
      </div>
      <ul class="info-list-bd">
        <li v-for="(item, index) in infoList" :key="index">
          <a :href="item.link" target="_blank" :clstag="clstag(item.linkClstag, '')">{{ item.title }}
          </a>
        </li>
      </ul>
    </div>

    <div v-if="showVideo">
      <video-wrap
        :video="video"
        @close="closeVideo"
      />
    </div>

  </div>
</template>
<script>

import SwiperS, { Navigation, Pagination, Autoplay, Mousewheel, EffectFade, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

import bgBanner1 from '@/assets/img/home/banner-1.png'
import bgBanner2 from '@/assets/img/home/banner-2.png'
import bgBanner3 from '@/assets/img/home/banner-3.png'
import pageClick from '@/utils/pageClick'
import videoWrap from './video'

SwiperS.use([Navigation, Pagination, Autoplay, Mousewheel, EffectFade, Controller])

export default {
  name: 'Banner',
  components: {
    videoWrap,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        effect: 'fade',
        pagination: {
          el: '.banner-swiper-pagination-container',
          clickable: true,
          renderBullet (index, className) {
            const bannerTitle = ['<b>一站式</b>物流系统对接平台', '开放平台管理端改版', '系统实施培训课程上线']
            return `<span class="${className}">${bannerTitle[index]}<i></i></span>`
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },

        allowTouchMove: false,
      },

      bannerList: [
        {
          bg: bgBanner1,
          title: '<b>一站式</b>物流系统对接平台',
          description: '构建客户与京东物流之间的技术生态，<br />助力企业实现物流供应链一体化',
          link: 'https://joyspace.jd.com/page/U6McxeFZXocPAGpMg9w8',
          linkClstag: 'Management_1702468662190|19',
          video: 'https://lcp-web.s3.cn-north-1.jdcloud-oss.com/video/%E5%BC%80%E6%94%BE%E5%B9%B3%E5%8F%B0%E7%AE%80%E4%BB%8B.mp4?AWSAccessKeyId=BF87810F4D2FD0096D06A8EC469C1A0E&Expires=1766647270&Signature=%2BzC1B0AM46UkbDe3io8nXRAndrM%3D',
          videoClstag: 'Management_1702468662190|20',
        },
        {
          bg: bgBanner2,
          title: '开放平台管理端<b>改版</b>',
          description: '【产研】使用手册文档整合优化，增加可读性<br />【系统实施】新增平台系统对接培训课程，更快熟悉平台用法<br />【公共】平台展示样式改版，加强新同学使用引导',
          link: 'https://joyspace.jd.com/pages/Sy3K7En5gQy5tlok678d',
          linkClstag: 'Management_1702468662190|21',
          video: '',
          videoClstag: '',
        },
        {
          bg: bgBanner3,
          title: '系统实施培训<b>课程上线</b>',
          description: '新增平台系统对接培训课程，<br />帮助业务同学快速熟悉开放平台使用流程和方法',
          link: 'https://joyspace.jd.com/pages/n0nGKhEgt2Vhn6CSfJ6S',
          linkClstag: 'Management_1702468662190|22',
          video: '',
          videoClstag: '',
        },

      ],

      infoList: [
        {
          title: '[01-11]【产品发布】系统实施培训课程上线',
          link: 'https://joyspace.jd.com/pages/n0nGKhEgt2Vhn6CSfJ6S',
          linkClstag: 'Management_1702468662190|24',
        },
        {
          title: '[01-11]【产品发布】开放平台管理端改版公告',
          link: 'https://joyspace.jd.com/pages/Sy3K7En5gQy5tlok678d',
          linkClstag: 'Management_1702468662190|23',
        },
      ],
      video: '',
      showVideo: false
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper
    },
  },
  mounted() {
    console.log('Current Swiper instance object', this.swiper)
  },
  methods: {
    playVideo(video) {
      if (video) {
        this.video = video
        this.showVideo = true
      }
    },
    closeVideo() {
      this.showVideo = false
    },
    getDetails(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
  }
}
</script>

<style lang="scss" scoped >

.banner-area {
  position: relative;
  height: 460px;

}

.banner-slide-item {
  height: 460px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .w {
    margin: 0 auto;
    width: 1080px;
  }

  .item-info {
    padding-top: 120px;
    text-align: left;
    width: 540px;

    .tit {
      font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
        "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-size: 36px;
      line-height: 40px;
      height: 40px;
      overflow: hidden;
      color: #23252B;

      /deep/ b {
        color: #3C6EF0;
        font-weight: normal;
      }
    }

    .txt {
      margin-top: 10px;
      font-size: 18px;
      line-height: 32px;
      height: 96px;
      overflow: hidden;
      color: #4F586E;
    }
  }

  .btn-box {
    margin-top: 30px;

    .btn-banner {
      font-size: 18px;
      padding: 13px 20px;
      min-width: 160px;
      line-height: 20px;
      border-radius: 8px;

      .icon-play {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../../../../assets/img/home/icon-video-play.png') no-repeat center;
        background-size: cover;
        margin-left: 4px;
        vertical-align: top;
      }

      &.is-plain {
        border-color: #fff;
        color: #3C6EF0;

        &:hover {
          border-color: #3C6EF0;
        }
      }

      &+.btn-banner {
        margin-left: 20px;
      }
    }
  }
}

.banner-swiper-pagination-container {
  position: absolute;
  bottom: 35px;
  left: 50%;
  margin-left: -540px;
  width: 600px;
  height: 40px;
  z-index: 1;

  /deep/ .swiper-pagination-bullet {
    vertical-align: top;
    width: 33.33%;
    height: 40px;
    line-height: 40px;
    margin: 0;
    background: rgba(255, 255, 255, 0.4);
    opacity: 1;
    color: #525765;
    border-radius: 0;
    position: relative;
    text-align: center;

    b {
      font-weight: normal;
    }

    i {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: rgba(228, 229, 233, 1);

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 67, 255, 1);
        transform-origin: left;
        transform: scaleX(0)
      }
    }

  }

  /deep/ .swiper-pagination-bullet-active {
    background: rgba(255, 255, 255, 0.8);
    color: #4F586E;

    i {
      display: block;

      &:after {
        animation: process 5.1s linear
      }
    }
  }

  @keyframes process {
    0% {
      transform: scaleX(0)
    }

    to {
      transform: scaleX(1)
    }
  }

}

.info-list {
  position: absolute;
  bottom: 110px;
  right: 50%;
  z-index: 1;
  box-sizing: border-box;
  margin-right: -540px;
  width: 430px;
  height: 236px;
  padding: 24px;
  background: linear-gradient(180deg, rgba(237, 242, 252, 0.88) 0%, rgba(255, 255, 255, 0.5808) 100%);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 2px 18px 0 rgba(144, 151, 171, 0.22);

  .info-list-hd {
    display: flex;
    justify-content: center;
    align-items: center;

    .tit {
      flex: 1;
      font-size: 20px;
      color: #23252B;
      line-height: 24px;
      font-weight: 500;
    }

    .more {
      text-align: right;
      line-height: 18px;
      color: #4F586E;

      i {
        vertical-align: top;
        margin-left: 5px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h16v16H0z' fill='%23FFF' fill-opacity='.5' opacity='.01'/%3E%3Cpath d='M8 .667a7.333 7.333 0 1 1 0 14.666A7.333 7.333 0 0 1 8 .667zM8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2zm1.68 6.856l-.079.085-2.196 2.196a.667.667 0 0 1-.943-.942L8.187 8.47c.24-.24.26-.619.056-.88l-.056-.063-1.725-1.722a.667.667 0 0 1-.056-.88l.056-.063c.26-.26.682-.26.942 0l2.197 2.194c.494.492.52 1.276.08 1.8z' fill='%23525765' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        background-size: cover;
      }

      &:hover {
        color: #3C6EF0;

        i {
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h16v16H0z' fill='%23FFF' fill-opacity='.5' opacity='.01'/%3E%3Cpath d='M8 .667a7.333 7.333 0 1 1 0 14.666A7.333 7.333 0 0 1 8 .667zM8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2zm1.68 6.856l-.079.085-2.196 2.196a.667.667 0 0 1-.943-.942L8.187 8.47c.24-.24.26-.619.056-.88l-.056-.063-1.725-1.722a.667.667 0 0 1-.056-.88l.056-.063c.26-.26.682-.26.942 0l2.197 2.194c.494.492.52 1.276.08 1.8z' fill='%233C6EF0' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");

        }
      }
    }
  }

  .info-list-bd {
    margin-top: 16px;
    li {
      line-height: 37px;
      a {
        color: #4F586E;
        font-size: 16px;
        &:hover {
          color: #3C6EF0;
        }
      }
    }
  }
}
</style>
