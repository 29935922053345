<template>
  <div class="content-role">
    <div class="role-title">平台服务角色</div>
    <div class="role-cards">
      <div class="grid-card">
        <div class="card-item" v-for="item in roleList" :key="item.title">
        <div class="item-head" @click="getDetails(item.link)" :clstag="clstag(item.clstag, '')">
          <div class="headr-content">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-description">{{ item.description }}</div>
          </div>
          <div class="header-icons">
            <div class="rectangle-icon" :style="{
              'background-image': `url(${item.icon})`,
            }">

            </div>
          </div>
        </div>

        <div class="item-tags">
          <div class="tags">
            <div class="tag-item" v-for="tag in item.tagList" :key="tag">
              <span class="tag-item-contex">{{ tag }}</span>
            </div>

          </div>
        </div>

        <div class="item-content">
          {{ item.content }}
        </div>
        <div class="item-footer">
          <div class="footer-buttons">
            <div class="button-item" v-for="footer in item.footerList" :key="footer.link">
              <span v-if="footer.link" @click="getDetails(footer.link)" :clstag="clstag(footer.clstag, '')">{{ footer.name }}</span>
              <span v-if="footer.video" @click="playVideo(footer.video)" :clstag="clstag(footer.clstag, '')">{{ footer.name }}</span>
              <div class="button-item-font"></div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div v-if="showVideo">
      <video-wrap
        :video="video"
        @close="closeVideo"
      />
    </div>

  </div>
</template>
<script>
import pageClick from '@/utils/pageClick'
import rectangleImg from '@/assets/front/rectangle.png'
import circularImg from '@/assets/front/circular.png'
import triangleImg from '@/assets/front/triangle.png'
import videoWrap from './video'

export default {
  components: {
    videoWrap,
  },
  data() {
    return {
      roleList: [
        {
          title: '我是产研',
          description: '',
          link: 'https://joyspace.jd.com/pages/0V8fUW5zdUiG09NW49a7',
          content: '产研按照平台规范进行接口的设计开发、文档维护和发布，不符合平台规范的接口可能会被拒绝发布。接口发布后，需要根据客户和业务的反馈定期完善文档和接口能力。',
          tagList: ['按照规范发布接口', '持续运维完善接口'],
          footerList: [
            {
              name: '介绍视频',
              video: 'https://lcp-web.s3.cn-north-1.jdcloud-oss.com/video/%E4%BA%A7%E7%A0%94%E4%BB%8B%E7%BB%8D.mp4?AWSAccessKeyId=BF87810F4D2FD0096D06A8EC469C1A0E&Expires=1766647648&Signature=dzB4cvfaYlTq9TZfxOQUfccNeDA%3D',
              clstag: 'Management_1702468662190|27'
            }],
          clstag: 'Management_1702468662190|26',
          icon: rectangleImg
          // footerList: [{ name: '介绍视频', link: '' }, { name: '去控制台', link: 'https://joyspace.jd.com/pages/0V8fUW5zdUiG09NW49a7' }]
        },
        {
          title: '我是系统实施',
          link: 'https://joyspace.jd.com/pages/rmSMQx16ThnP16jyW7KR',
          description: '',
          content: '学习平台系统对接培训课程，迅速掌握客户系统对接流程。配合多种平台运维工具，解决客户系统对接问题。',
          tagList: ['学习平台对接流程', '使用平台工具运维'],
          footerList: [
            {
              name: '介绍视频',
              video: 'https://lcp-web.s3.cn-north-1.jdcloud-oss.com/video/%E7%B3%BB%E7%BB%9F%E5%AE%9E%E6%96%BD%E4%BB%8B%E7%BB%8D.mp4?AWSAccessKeyId=BF87810F4D2FD0096D06A8EC469C1A0E&Expires=1766647736&Signature=P6BQ5wladZOryH4Xs9kfyemG6Uk%3D',
              clstag: 'Management_1702468662190|29'
            }],
          clstag: 'Management_1702468662190|28',
          icon: circularImg,
          // footerList: [{ name: '介绍视频', link: '' }, { name: '去控制台', link: 'https://joyspace.jd.com/pages/rmSMQx16ThnP16jyW7KR' }]
        },
        {
          title: '我是IT',
          link: 'https://joyspace.jd.com/pages/HQcwaUTsWXYJtyERtQst',
          description: '',
          content: '处理平台/知更工单、京ME群、微信群中客户或业务同学的系统对接问题。对存在质量问题的功能和接口负责人派发问题工单，并追踪问题单解决进度。',
          tagList: ['解决客户对接问题', '追踪问题工单进度'],
          footerList: [
            {
              name: '介绍视频',
              video: 'https://lcp-web.s3.cn-north-1.jdcloud-oss.com/video/IT%E4%BB%8B%E7%BB%8D.mp4?AWSAccessKeyId=BF87810F4D2FD0096D06A8EC469C1A0E&Expires=1766647685&Signature=YKSFpuJ4R5%2BhiOQBFqpSjjzMu3A%3D',
              clstag: 'Management_1702468662190|31'
            }],
          clstag: 'Management_1702468662190|30',
          icon: triangleImg
          // footerList: [{ name: '介绍视频', link: '' }, { name: '去控制台', link: 'https://joyspace.jd.com/pages/HQcwaUTsWXYJtyERtQst' }]
        }
      ],
      video: '',
      showVideo: false
    }
  },
  methods: {
    closeVideo() {
      this.showVideo = false
    },
    playVideo(video) {
      this.video = video
      this.showVideo = true
    },
    getDetails(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
  }
}
</script>

<style lang="scss" scoped>
.content-role {
  height: 480px;
  text-align: center;
  background-color: #F4F7FB;

  .role-title {
    width: 100%;
    font-size: 30px;
    padding: 50px 0;
    line-height: 30px;
    color: #23252B;
    text-align: center;
    font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
    "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .role-cards {
    box-sizing: border-box;
    width: 1080px;
    display: inline-block;
    -webkit-box-align: center;
    align-items: center;
    .grid-card {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      margin-left: -30px;
    }
    .card-item {
      height: 290px;
      overflow: hidden;
      vertical-align: top;
      margin-left: 30px;
      display: inline-block;
      position: relative;
      background: linear-gradient(180deg, #BDD8FF 0%,#FFFFFF 33%, #FFFFFF 100%);
      border-radius: 10px;
      box-shadow:  2px 2px 31px 9px rgba(213,222,239,0.41);
      transition: all .3s ease;
      &:hover {
        transform: scale(1.05);
        .headr-content {
          flex: 1;
          text-align: left;
          .item-title {
            color: #3C6EF0 !important;
          }
          .item-description {
            color: rgba(60,110,240,0.7) !important;
          }
        }
      }
      .item-head {
        display: flex;
        cursor: pointer;
        margin: 18px 14px 0 24px;
        .headr-content {
          flex: 1;
          text-align: left;
          .item-title {
            font-size: 20px;
            color: #23252B;
            font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
            "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
          }
          .item-description {
            font-size: 14px;
            margin-top: 11px;
            color: rgba(82,87,101,0.7);
          }
        }
        .header-icons {
          // flex: 1;
          .rectangle-icon {
            width: 85px;
            height: 64px;
            background-size: cover;
          }
        }
      }
      .item-tags {
        margin: 16px 24px 14px 24px;
        .tags {
          text-align: left;
          .tag-item {
            height: 28px;
            display: inline-block;
            border: 1px solid #E4E5E9;
            border-radius: 8px;
            margin-right: 8px;
            .tag-item-contex {
              color: #525765;
              margin: 12px;
              font-size: 12px;
              line-height: 12px;
              vertical-align: text-bottom;
            }
          }
        }
      }
      .item-content {
        margin: 0 24px;
        color: #868D9F;
        text-align: left;
        line-height: 24px;
      }
      .item-footer {
        position: absolute;
        right: 36px;
        bottom: 24px;
        .footer-buttons {
          text-align: right;
          color: #868D9F;
          .button-item {
            cursor: pointer;
            margin-left: 16px;
            display: inline-block;
            &:hover {
              color: #3C6EF0;
            }
            .button-item-font {
              width: 20px;
              height: 20px;
              display: inline-block;
              vertical-align: top;
              background-size: cover;
              background-image: url('../../../../assets/front/Lui-icon-arrow-right-min.png');
              &:hover {
                background-image: url('../../../../assets/front/Lui-icon-arrow-right-min-active.png');
              }
            }
          }
        }
      }

    }
  }
}
</style>
