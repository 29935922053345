<template>
  <div class="content-case">
    <div class="case-title">最佳实践案例</div>

    <div class="case-cards">
      <el-carousel trigger="click" :autoplay="true" arrow="always" :interval=5000 >
        <el-carousel-item v-for="item in caseList" :key="item.title">
          <div class="card-box">
            <div class="temp-item">
              <div class="case-card-image" :style="{
                'background-image': `url(${item.bg})`,
              }">
              </div>
              <div class="case-card-content">
                <div class="content-area">
                  <div class="card-content-title" @click="getDetails(item.link)" :clstag="clstag(item.clstag, '')">{{ item.title }}</div>
                  <div class="card-content-description">{{ item.description }}</div>
                  <div class="card-content-footer" @click="getDetails(item.link)" :clstag="clstag(item.clstag, '')">了解更多
                    <span class="button-item-font"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import dewu from '@/assets/front/dewu.png'
import debang from '@/assets/front/debang.png'
import pageClick from '@/utils/pageClick'

export default {
  data() {
    return {
      caseList: [
        {
          title: '京东物流-德邦网络融合',
          description: '京东物流与德邦的基础设施网络融合，实现下单、运输、分拣、质控、工单等系统的打通，并对下单、修改等关键接口进行加密传输。',
          bg: debang,
          link: 'https://joyspace.jd.com/pages/7QxlwrS5QhQtYX7eTOQ9',
          clstag: 'Management_1702468662190|33'
        },
        {
          title: '得物快递下单接口改造',
          description: '为防止得物平台客户敏感信息在公网传输时被泄露，京东物流对得物接单接口进行数据传输安全改造，对存在客户信息的接口做请求和响应报文加密。',
          bg: dewu,
          link: 'https://joyspace.jd.com/pages/YlJGpww0hBtTGqWllIrj',
          clstag: 'Management_1702468662190|34'
        }
      ]
    }
  },
  methods: {
    getDetails(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
  }
}
</script>

<style lang="scss" scoped>
.content-case {
  height: 511px;
  text-align: center;
  background-color: #F4F7FB;

  .case-title {
    width: 100%;
    font-size: 30px;
    padding: 50px 0;
    line-height: 30px;
    color: #23252B;
    text-align: center;
    font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
            "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .case-cards {
    box-sizing: border-box;
    width: 1080px;
    display: inline-block;
    align-items: center;

    .card-box {
      text-align: center;
      display: inline-block;
      .temp-item {
        width: 980px;
        display: grid;
        grid-template-columns: 272px 1fr;
        align-items: center;
        .case-card-image {
          width: 272px;
          height: 272px;
          background-image:  url('../../../../assets/front/dewu.png');
          background-size: cover;
        }
        .case-card-content {
          width: 683px;
          height: 272px;
          margin-left: 25px;
          background-color: #fff;
          .content-area {
            height: 200px;
            margin: 36px;
            text-align: left;
            vertical-align: middle;
            display: inline-block;
            position: relative;
          }
          .card-content-title {
            cursor: pointer;
            font-size: 25px;
            line-height: 20px;

            font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
            "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
          }
          .card-content-description {
            color: #868D9F;
            font-size: 16px;
            line-height: 30px;
            margin-top: 20px;
            font-family: "PingFang SC", "Microsoft YaHei", "黑体",
            "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
          }
          .card-content-footer {
            position: absolute;
            right: 0;
            bottom: 0;
            cursor: pointer;
            .button-item-font {
              width: 20px;
              height: 20px;
              margin-left: -8px;
              display: inline-block;
              vertical-align: top;
              background-size: cover;
              background-image: url('../../../../assets/front/Lui-icon-arrow-right-min.png');
            }
            &:hover {
              color: #3C6EF0;
              .button-item-font {
                background-image: url('../../../../assets/front/Lui-icon-arrow-right-min-active.png');
              }

              }
          }
        }

      }
    }

    /deep/ .el-carousel  {
        .el-carousel__container {
          .el-carousel__arrow {
            width: 15px;
            transition: none !important;
            background-color: transparent;
            i {
              display: none;
            }
          }
          .el-carousel__arrow--left {
            left: 0;
            // top: 50%;
            background-image: url('../../../../assets/front/light-arrow.png');
            background-size: cover;
            &:hover {
              background-image: url('../../../../assets/front/arrow.png');
              object-fit: cover;
              transform: scaleX(-1);
              top: 44%;
            }
          }
          .el-carousel__arrow--right {
            right: 0;
            top: 45%;
            background-image: url('../../../../assets/front/light-arrow.png');
            background-size: cover;
            object-fit: cover;
            transform: scaleX(-1);
            &:hover {
              object-fit: cover;
              transform: scaleX(1);
              background-image: url('../../../../assets/front/arrow.png');
            }
          }
        }
      }
  }
  /deep/.el-carousel__indicators {
    .el-carousel__indicator--horizontal  {
      .el-carousel__button {
        width: 17px;
        height: 5px;
        border-radius: 5px;
        background-color: #D8D8D8 !important;
      }
    }
    .is-active {
      .el-carousel__button {
        width: 59px;
        height: 5px;
        background: rgba(60,110,240,1) !important;
        border-radius: 5px;
      }
    }
  }

}
</style>
