<template>
  <div class="content-work">
    <div class="work-title">平台日常工作</div>
    <div class="work-cards">
      <div class="grid-card">
        <div class="card-item" v-for="item in workList" :key="item.title">
          <div class="item-cover" :style="{
          'background-image': `url(${item.bg})`,
        }"></div>
          <div class="item-content">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-description"> {{ item.content }}</div>
          </div>
          <button v-if="item.button" class="item-button" @click="getDetails(item.link)" :clstag="clstag('Management_1702468662190|32', '')">
            了解详情
          </button>
          <button v-else class="item-button item-button-disable" type="info">
            建设中
          </button>
      </div>
      </div>
    </div>
  </div>
</template>
<script>

import customImg from '@/assets/front/cust.png'
import platFormImg from '@/assets/front/plat.png'
import systemImg from '@/assets/front/system.png'
import stabilizeImg from '@/assets/front/stabilizeImg.png'

import pageClick from '@/utils/pageClick'

export default {
  data() {
    return {
      workList: [
        {
          title: '客户技术体验优化',
          content: '优化客户接入流程，建设配套工具提升客户系统对接体验',
          tagList: ['遵守开放接口规范', '持续运维完善接口'],
          bg: customImg
        },
        {
          title: '平台对外接口治理',
          content: '从API设计、文档到发布运营，建立规范体系约束接口质量',
          tagList: ['学习系统对接流程', '认证系统实施能力'],
          button: true,
          bg: platFormImg,
          link: 'https://joyspace.jd.com/pages/HXzg4aEmFPq8TN1uVeRG'
        },
        {
          title: '客户系统稳定监管',
          content: '建设接口运行看板、异常告警、日志查询等多种监控工具，线上异常及时通知负责人',
          tagList: ['熟悉平台使用流程', '日常处理问题工单'],
          bg: systemImg,
        },
        {
          title: '协作流程机制保障',
          content: '建立业务需求提报、接口文档优化、客户对接运维等流程机制，提升BP、业务、IT等部门间协作效率',
          tagList: ['熟悉平台使用流程', '日常处理问题工单'],
          bg: stabilizeImg
        }
      ]
    }
  },
  methods: {
    getDetails(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
  }
}
</script>

<style lang="scss" scoped>
.content-work {
  height: 474px;
  text-align: center;
  background-color: #FFFFFF;

  .work-title {
    width: 100%;
    font-size: 30px;
    padding: 50px 0;
    line-height: 30px;
    color: #23252B;
    text-align: center;
    font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
            "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;

  }
  .work-cards {
    box-sizing: border-box;
    width: 1080px;
    display: inline-block;
    -webkit-box-align: center;
    align-items: center;
    cursor: default;
    .grid-card {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      margin-left: -20px;
    }
    .card-item {
      height: 304px;
      overflow: hidden;
      vertical-align: top;
      margin-left: 20px;
      display: inline-block;
      position: relative;
      transition: all .3s ease;
      &:hover {
        transform: scale(1.05);
        .item-button {
          color: #fff;
          background-color: #3C6EF0;
          opacity: 1;
          transition: all .3s ease;
        }
        .item-button-disable {
          color: #B5B5B5;
          background-color: #F5F5F5;
          border-color: #c5c5c5;
        }
      }
      .item-cover {
        width: 90px;
        height: 90px;
        margin-top: 24px;
        text-align: center;
        display: inline-block;
        background-size: cover;
      }
      .item-content {
        margin: 0 16px;
        .item-title {
          line-height: 20px;
          font-size: 20px;
          font-weight: 500;
          color: #23252B;
          margin-top: 24px;
          margin-bottom: 10px;
        }
        .item-description {
          height: 48px;
          font-size: 13px;
          line-height: 24px;
          color: #868D9F;
        }
      }
      .item-button {
        text-align: center;
        align-items: center;
        background-color: transparent;
        border-radius: 8px;
        box-sizing: border-box;
        color: #FFFFFF;
        cursor: pointer;
        font-size: 16px;
        line-height: 18px;
        height: 40px;
        justify-content: center;
        line-height: 24px;
        overflow: hidden;
        position: relative;
        width: 112px;
        z-index: 1;
        opacity: 0;
        margin: 24px 0;
      }
      .item-button-disable {
        cursor: not-allowed;

      }
    }
  }
}
</style>
