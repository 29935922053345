<template>
  <div>
    <banner />
    <content-role />
    <content-work />
    <case-area />
  </div>
</template>

<script>
import banner from './components/banner.vue'
import contentRole from './components/contentRole.vue'
import contentWork from './components/contentWork.vue'
import caseArea from './components/case.vue'

export default {
  components: {
    banner,
    contentRole,
    contentWork,
    caseArea,
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
@import "~@/assets/styles/mixin.scss";
</style>
