<template>
  <div>
    <div class="activity-pop pop-video">
      <div class="flex-center">
        <div class="video-wrap">
          <div class="video-close" @click="closeVideo">x</div>
          <video controls class="video">
            <source :src="video" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    video: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeVideo() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.activity-pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  background: rgba(0,0,0,.82);
  color: #fff;
  .flex-center {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    .video-wrap {
      position: relative;
      width: 800px;
      height: 450px;
      background: #000;
      position: relative;
      .video {
        width: 100%;
        height: 100%;
      }
      .video-close {
        background-color: rgba(0,0,0,.2);
        position: absolute;
        top: 5px;
        right: 5px;
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 26px;
        font-size: 32px;
        text-align: center;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
}
.pop-video {
  display: block;
}
</style>
